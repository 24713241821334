import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import * as PropTypes from 'prop-types';
import Head from 'components/Head';
import marked from 'marked';

import styles from 'styles/pages/privacy.module.scss';

class PagePrivacy extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { title, body, photo } = data.contentfulPagePrivacy;
    const bodyFormatted = marked(body.body);
    return (
      <>
        <Head />
        <div className={styles.content}>
          <div className={styles.imageWrapper}>
            <Img className={styles.image} alt={photo.description} fluid={photo.fluid} />
          </div>
          <div className={styles.copyWrapper}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.copy} dangerouslySetInnerHTML={{ __html: bodyFormatted }} />
          </div>
        </div>
      </>
    );
  }
}

PagePrivacy.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PagePrivacy;

export const pageQuery = graphql`
  query {
    contentfulPagePrivacy {
      title
      body {
        body
      }
      photo {
        id
        fluid {
          aspectRatio
          src
          srcSet
        }
        description
      }
    }
  }
`;
